import axios from 'axios';

export const api = (version: string = 'v1') => {
	const handler = axios.create({
		baseURL: `${process.env.NEXT_PUBLIC_API_URL}/${version}/jobsite/`,
		headers: {
			'Content-Type': 'application/json',
		},
		withCredentials: true,
	});

	if (typeof window === 'undefined') {
		handler.defaults.headers['Origin'] = process.env.NEXT_PUBLIC_ORIGIN_HEADER;
	}

	return handler;
};
