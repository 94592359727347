import { RemoteErrors } from '../../types';

export default function remoteErrorsToString(
	remoteErrors: RemoteErrors,
): string {
	let errors = '';
	if (remoteErrors.response?.data?.errors) {
		if (typeof remoteErrors.response.data.errors === 'string')
			return remoteErrors.response.data.errors;
		errors = Object.values(remoteErrors.response.data.errors).flat().join(', ');
	}
	return errors;
}
