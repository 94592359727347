import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function FormError({ error }: { error: string }) {
	return (
		<div className='bg-red-900 flex items-center text-white font-semibold text-base mt-2 p-2 px-4 rounded-lg'>
			<FontAwesomeIcon icon={faInfoCircle} />
			<p className='ml-2'>{error}</p>
		</div>
	);
}
