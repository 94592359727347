import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

type Props = {
	children: ReactNode;
};

function BodyPortal({ children }: Props) {
	const domNode = document.getElementById('modal-portal');
	if (domNode === null) return <div></div>;
	return ReactDOM.createPortal(children, domNode);
}

export default function TooltipPortalWrapper({ children }: Props) {
	if (typeof document === 'undefined') return null;
	return <BodyPortal>{children}</BodyPortal>;
}
