import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import validateEmail from 'pruve/dist/validators/email';
import Button from '../../Button/Button';
import { Input } from '../../Input/Input';
import Modal from '../../Modal/Modal';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import useDevice from '../../../api/hooks/useDevice';
import useKeywords from '../../../api/hooks/useKeywords';
import { useRegisterForAlertsMutation } from '../../../api/hooks/useRegisterForAlertsMutation';
import { successToast } from '../../Toast/successToast';
import remoteErrorsToString from '../../../utils/remoteErrorsToString';

type Props = {
	show: boolean;
	onClose: () => void;
};

export default function AlertsSignupModal({ show, onClose }: Props) {
	const { control, handleSubmit, errors } = useForm();
	const { data: keywords = [] } = useKeywords();
	const [fetchError, setFetchError] = useState('');

	const registerForAlertsMutation = useRegisterForAlertsMutation({
		onSuccess: () => {
			onClose();
			successToast('Signed up for alerts!');
			setFetchError('');
		},
		onError: error => setFetchError(remoteErrorsToString(error)),
	});

	const onSubmit = handleSubmit(
		(data: {
			firstName: string;
			surname: string;
			email: string;
			tags: string[];
		}) => {
			registerForAlertsMutation.mutate({ data });
		},
	);

	const { isSmallest, isPhone } = useDevice();

	return (
		<Modal
			show={show}
			header='Register for alerts'
			onClose={() => {
				setFetchError('');
				onClose();
			}}
			buttons={
				<div className='w-full flex justify-end'>
					<Button
						onClick={onSubmit}
						ariaLabel='Register for alerts'
						icon={faEnvelope}
						backgroundColor='#37c89f'
						thin={isSmallest}
					>
						Register for alerts
					</Button>
				</div>
			}
		>
			<div className='flex flex-col gap-4'>
				{fetchError !== '' && (
					<p className='text-red-900'>{`Error: ${fetchError}`}</p>
				)}
				<p>
					To receive tailored job alerts we will just need a few details from
					you.
				</p>
				<div className='flex flex-col gap-4'>
					<div className='flex flex-col md:flex-col gap-4'>
						<Controller
							name='firstName'
							defaultValue=''
							rules={{
								required: 'Please enter your first name',
							}}
							control={control}
							render={props => (
								<Input
									label='First name'
									type='text'
									onChange={value => {
										props.onChange(value);
									}}
									value={props.value}
									name='firstName'
									placeholder='Please enter your first name'
									extraClasses='text-slate-900 w-full'
									error={errors?.firstName?.message}
									required
								/>
							)}
						/>
						<Controller
							name='surname'
							defaultValue=''
							rules={{
								required: 'Please enter your surname',
							}}
							control={control}
							render={props => (
								<Input
									label='Surname'
									type='text'
									onChange={value => {
										props.onChange(value);
									}}
									value={props.value}
									name='surname'
									placeholder='Please enter a surname'
									extraClasses='text-slate-900 w-full'
									error={errors?.surname?.message}
									required
								/>
							)}
						/>
						<Controller
							name='email'
							defaultValue=''
							rules={{
								required: 'Please enter a valid email address',
								validate: {
									validEmail: (value: string) => {
										return (
											validateEmail(value) ||
											'Please enter a valid email address'
										);
									},
								},
							}}
							control={control}
							render={props => (
								<Input
									label='Email'
									type='text'
									onChange={value => {
										props.onChange(value);
									}}
									value={props.value}
									name='email'
									placeholder='Please enter an email address'
									extraClasses='text-slate-900 w-full'
									error={errors?.email?.message}
									required
								/>
							)}
						/>
					</div>
					<Controller
						name='tags'
						defaultValue=''
						control={control}
						rules={{
							required: 'Please enter at least one skill',
						}}
						render={props => (
							<SearchSelect
								onChange={value => {
									if (value === null) return props.onChange(null);
									props.onChange(value);
								}}
								options={keywords.map(value => {
									return { value: value, label: value };
								})}
								placeholder='Please enter a few of your skills'
								name='tags'
								value={props.value}
								label='Skills'
								isClearable={true}
								error={errors?.tags?.message}
								isMulti={true}
								customOptions={true}
								tooltip='Add a list of your strongest skills. These will be used to match you with potential job opportunities.'
								required
								customNoResultsMessage='Type in a skill, then press enter to add another one'
								menuPlacement={isPhone && !isSmallest ? 'auto' : 'top'}
							/>
						)}
					/>
				</div>
			</div>
		</Modal>
	);
}
