import create from 'zustand';
import createBase from 'zustand/vanilla';
import { combine } from 'zustand/middleware';

export const baseStore = createBase(
	combine(
		{
			loading: false,
			showAlertsSignupModal: false,
		},
		set => ({
			setLoading: (value: boolean) => {
				set(() => ({
					loading: value,
				}));
			},
			setShowAlertsSignupModal: (value: boolean) => {
				set(() => ({
					showAlertsSignupModal: value,
				}));
			},
		}),
	),
);

export const useStore = create(baseStore);
