import React from 'react';
import classnames from 'classnames';
import Tooltip from '../Tooltip/Tooltip';

export type FormLabelProps = {
	label?: string;
	required?: boolean;
	tooltip?: React.ReactNode | string;
	tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
	name: string;
	extraLabelClasses?: string;
	inline?: boolean;
};

export function FormLabel({
	label,
	required,
	tooltip,
	tooltipPosition = 'top',
	name,
	extraLabelClasses,
	inline = false,
}: FormLabelProps) {
	return (
		<div
			className={classnames(
				`ml-2 origin-top-left leading-4 ${extraLabelClasses}`,
				{ 'py-4': inline, 'mt-2': inline === false },
			)}
		>
			{label && (
				<label
					htmlFor={name}
					className='pointer-events-none text-slate-900 text-base font-semibold inline'
				>
					{label}
				</label>
			)}
			{required && (
				<span className='pointer-events-none text-red-500 z-10'> *</span>
			)}
			{tooltip && (
				<span className='ml-2 mt-1'>
					<Tooltip id={name} content={tooltip} position={tooltipPosition} />
				</span>
			)}
		</div>
	);
}
