import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

type Props = {
	show: boolean;
	children: ReactNode;
	onClose?: () => void | undefined;
	header: string | JSX.Element;
	buttons?: ReactNode;
	large?: boolean;
	tall?: boolean;
	short?: boolean;
	extraLarge?: boolean;
	gridDisplay?: boolean;
	isPortalled?: boolean;
};

export default function Modal({
	header,
	show,
	children,
	onClose,
	buttons,
	large = false,
	extraLarge = false,
	tall = false,
	short = false,
	gridDisplay,
	isPortalled = false,
}: Props) {
	if (typeof window === 'undefined') return null;
	const portalTarget = document.getElementById('modal-portal');
	if (!show) return null;

	const render = () => {
		return (
			<div
				className={classnames(
					'fixed z-50 inset-0 overflow-y-none px-2 lg:px-8',
				)}
			>
				<div className='min-h-screen md:pt-4 md:px-4 pb-20 text-center block sm:p-0'>
					<div
						onClick={onClose && onClose}
						className='absolute inset-0 bg-slate-300 opacity-50'
					></div>
					<span
						className='inline-block align-middle h-screen'
						aria-hidden='true'
					>
						&#8203;
					</span>

					<div
						className={classnames(
							'md:relative bg-pink-100 rounded-lg text-left shadow-pink transform align-middle w-full overflow-hidden',
							{
								'max-w-4xl': large,
								'max-h-[100vh] max-w-[1920px]': extraLarge,
								'max-h-[95vh] sm:my-8': !extraLarge,
								'max-w-3xl': !large && !extraLarge,
								'min-h-[60vh]': short,
								'min-h-[87vh]': tall,
							},

							gridDisplay ? 'inline-grid' : 'inline-block',
						)}
						role='dialog'
						aria-modal='true'
						aria-labelledby='modal-headline'
					>
						<div className='flex items-stretch'>
							<div className='py-12 px-6 lg:px-12 flex flex-col max-h-[98vh] relative flex-1 bg-white h-full'>
								{onClose && (
									<button
										onClick={onClose}
										className={classnames(
											'top-6 right-6 absolute text-slate-900 font-bold',
										)}
										aria-label='Close Modal'
									>
										<FontAwesomeIcon icon={faTimes} fixedWidth size='1x' />
									</button>
								)}
								{typeof header === 'string' ? (
									<h1
										id='modal-headline'
										className='text-xl flex items-center flex-1 max-h-24 md:text-2xl text-left mb-4 text-slate-900 break-words'
									>
										{header}
									</h1>
								) : (
									<div className='flex items-center flex-1 max-h-30 text-xl md:text-2xl text-left mb-4 text-slate-900 '>
										{header}
									</div>
								)}
								<div className='flex-1 overflow-y-auto p-1 pr-2'>
									{children}
								</div>
								{buttons && (
									<div className='mt-4 pt-4 flex-1 flex max-h-24 justify-between items-center'>
										{buttons}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return isPortalled && portalTarget
		? ReactDOM.createPortal(render(), portalTarget)
		: render();
}
