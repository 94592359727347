import { useState, useEffect } from 'react';

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: Infinity,
		height: Infinity,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		if (window) {
			window.addEventListener('resize', handleResize);
			handleResize();
			return () => window.removeEventListener('resize', handleResize);
		}
	}, []);

	return windowSize;
}
