import { api } from '../ApiHandler';

export const RegisterForAlertsRequest = (data: {
	firstName: string;
	surname: string;
	email: string;
	tags: string[];
}): Promise<{ data: {} }> => {
	return api()
		.post(`/candidates/register`, data)
		.then(response => response.data);
};
