import { useWindowSize } from './useWindowSize';

export default function useDevice() {
	const { width } = useWindowSize();
	return {
		isSmallest: width < 768,
		isPhone: width < 1024,
		isTablet: width >= 768 && width <= 1280,
		// Tablet or phone
		isMobile: width <= 1280,
		isDesktop: width > 1280 && width <= 3800,
		isUHD: width > 3800,
	};
}
