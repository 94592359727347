import React from 'react';
import ReactTooltip from 'react-tooltip';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import TooltipPortalWrapper from './TooltipPortalWrapper';

type Props = {
	id: string;
	content: React.ReactNode | string;
	position?: 'top' | 'right' | 'bottom' | 'left';
	children?: React.ReactNode;
	show?: boolean;
	depth?: 'z-10' | `z-20` | 'z-30' | `z-40` | 'z-50';
};

export default function Tooltip({
	id,
	content,
	position = 'top',
	children,
	show = true,
	depth = 'z-30',
}: Props) {
	if (!show) {
		return <div>{children}</div> || null;
	}
	return (
		<div className={classnames('relative inline-block', depth)}>
			<div data-tip data-for={id}>
				{children || (
					<FontAwesomeIcon
						icon={faQuestionCircle}
						className='text-slate-900 h-3 w-3'
					/>
				)}
			</div>
			<TooltipPortalWrapper>
				<ReactTooltip
					id={id}
					place={position}
					className='tooltip z-999'
					arrowColor='transparent'
					backgroundColor='white'
					textColor='black'
					wrapper={undefined}
					clickable
					effect='solid'
				>
					<span className='block max-w-[18rem]'>{content}</span>
				</ReactTooltip>
			</TooltipPortalWrapper>
		</div>
	);
}
