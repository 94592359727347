import { useMutation } from 'react-query';
import { RemoteErrors } from '../../../types';
import { useStore } from '../../store/store';
import { RegisterForAlertsRequest } from '../requests/RegisterForAlertsRequest';

type Props = {
	onSuccess?: () => void;
	onMutate?: () => void;
	onError?: (error: RemoteErrors) => void;
};

export const useRegisterForAlertsMutation = ({
	onSuccess,
	onError,
	onMutate,
}: Props) => {
	const setLoading = useStore(state => state.setLoading);
	return useMutation(
		({
			data,
		}: {
			data: {
				firstName: string;
				surname: string;
				email: string;
				tags: string[];
			};
		}) => RegisterForAlertsRequest(data),
		{
			onSuccess: () => {
				if (onSuccess) onSuccess();
			},
			onMutate: () => {
				setLoading(true);
				if (onMutate) onMutate();
			},
			onError: (error: RemoteErrors) => {
				if (onError) onError(error);
			},
			onSettled: () => {
				setLoading(false);
			},
		},
	);
};
