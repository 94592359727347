import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import GenericToast from './GenericToast';

export const successToast = (message?: ReactNode, props = {}) => {
	toast(
		() => <GenericToast icon={faCheck}>{message || 'All done!'}</GenericToast>,
		{
			type: 'success',
			position: 'bottom-right',
			hideProgressBar: true,
			icon: false,
			toastId: 'success',
			...props,
		},
	);
};
