import React, { ReactText, useEffect, useRef } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
	ValueType,
	OptionsType,
	OptionTypeBase,
	ClassNamesState,
} from 'react-select';
import { FormLabel } from '../FormLabel/FormLabel';
import FormError from '../FormError/FormError';

interface SearchSelectBaseProps {
	onChange: (value: ValueType<OptionTypeBase, false> | string[]) => void;
	options: OptionsType<any>;
	name: string;
	value: string | string[] | null;
	placeholder?: string;
	isMulti?: boolean;
	customOptions?: boolean;
	white?: boolean;
	menuIsOpen?: boolean;
	menuPlacement?: 'bottom' | 'auto' | 'top';
	required?: boolean;
	tooltip?: string;
	disabled?: boolean;
	error?: string;
	isPortalled?: boolean;
	isClearable?: boolean;
	redacted?: boolean;
	setText?: (value: string) => void;
	tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
	customNoResultsMessage?: string;
	autoFocus?: boolean;
}
interface WithLabel extends SearchSelectBaseProps {
	label: string;
	ariaLabel?: never;
}
interface WithAriaLabel extends SearchSelectBaseProps {
	label?: never;
	ariaLabel: string;
}

export type SearchSelectProps = WithLabel | WithAriaLabel;

export function SearchSelect({
	label,
	ariaLabel,
	onChange,
	options,
	isClearable = true,
	name,
	value,
	isMulti = false,
	error,
	placeholder = 'Please select an option',
	customOptions = false,
	white = false,
	menuIsOpen = undefined,
	menuPlacement = 'auto',
	required = false,
	tooltip,
	disabled,
	isPortalled = true,
	setText,
	tooltipPosition,
	autoFocus = false,
	customNoResultsMessage,
}: SearchSelectProps): JSX.Element {
	const ref = useRef(null);

	useEffect(() => {
		if (ref !== null && ref.current !== null && autoFocus === true) {
			ref.current.focus();
		}
	});

	const SelectComponent = (
		customOptions ? CreatableSelect : Select
	) as React.ElementType;

	const flattenedOptions = options.flatMap(option => {
		if (option.options === undefined) {
			return option;
		}
		return option.options;
	});

	const selectedValue = Array.isArray(value)
		? value.map((tag: string) => {
				return {
					value: tag,
					label:
						options.filter(option => option.value === tag)[0]?.label ?? tag,
				};
		  })
		: flattenedOptions.filter(option => option.value === value);

	const getNoOptionsMessage = () => {
		if (customNoResultsMessage !== undefined) return customNoResultsMessage;
		if (customOptions === false)
			return 'No options found. Please adjust your search.';
		if (customOptions === true && isMulti === true)
			return placeholder || 'Enter your own tags.';
		if (customOptions === true && isMulti === false)
			return 'No options found. Please enter your own.';
	};

	return (
		<div className='flex flex-col w-full'>
			<div
				className={classnames(
					'flex flex-col w-full border-0 rounded-lg relative',
					{ 'opacity-70 pointer-events-none': disabled },
					white ? 'bg-white' : 'bg-pink-900',
				)}
			>
				<FormLabel
					name={name}
					label={label}
					tooltip={tooltip}
					required={required}
					tooltipPosition={tooltipPosition}
				/>
				<SelectComponent
					onChange={(selectValue: ValueType<OptionTypeBase, boolean>) => {
						if (Array.isArray(selectValue)) {
							const newValues = selectValue?.map(value => value.value);
							return onChange(newValues);
						}
						onChange(selectValue);
					}}
					data-testid='react-select'
					isDisabled={disabled}
					value={selectedValue}
					classNamePrefix='react-select'
					placeholder={placeholder}
					name={name}
					isClearable={isClearable}
					noOptionsMessage={getNoOptionsMessage}
					aria-label={label || ariaLabel}
					options={options}
					isMulti={isMulti}
					menuIsOpen={menuIsOpen}
					menuPlacement={menuPlacement}
					onInputChange={(value: string) => {
						if (setText) setText(value);
					}}
					ref={ref}
					formatCreateLabel={(value: ReactText) => {
						return (
							<p>
								Add <strong>{value}</strong>
							</p>
						);
					}}
					menuPortalTarget={
						isPortalled && typeof document !== 'undefined'
							? document.body
							: null
					}
					styles={{
						control: (provided: CSSStyleDeclaration) => {
							const newValues = provided;
							return {
								...newValues,
								border: 'none',
								backgroundColor: white
									? '#fff'
									: 'rgba(253, 245, 247, var(--tw-bg-opacity))',
								marginBottom: 0,
								minHeight: '33px',
								borderRadius: '0.5rem',
								lineHeight: '1rem',
							};
						},
						menuPortal: (provided: CSSStyleDeclaration) => ({
							...provided,
							zIndex: 9999,
						}),
						groupHeading: (provided: CSSStyleDeclaration) => ({
							...provided,
							textTransform: 'capitalize',
							color: '#0C1D36',
							fontWeight: '600',
						}),
						option: (provided: CSSStyleDeclaration, state: ClassNamesState) => {
							let backgroundColor = white
								? '#fff'
								: 'rgba(253, 245, 247, var(--tw-bg-opacity))';
							return {
								...provided,
								backgroundColor:
									state?.isFocused === false
										? backgroundColor
										: 'rgba(204, 235, 255, var(--tw-bg-opacity))',
								color: '#0C1D36',
								cursor: 'pointer',
							};
						},
						container: (provided: CSSStyleDeclaration) => {
							const newValues = provided;
							return {
								...newValues,
								backgroundColour: 'transparent',
							};
						},
						menu: (provided: CSSStyleDeclaration) => ({
							...provided,
							marginTop: 0,
							bottom: 0,
							border: 0,
							padding: 0,
							margin: 0,
							zIndex: 40,
							backgroundColor: white
								? '#fff'
								: 'rgba(253, 245, 247, var(--tw-bg-opacity))',
						}),
						indicatorSeparator: (provided: CSSStyleDeclaration) => ({
							...provided,
							width: 0,
						}),
						dropdownIndicator: (provided: CSSStyleDeclaration) => ({
							...provided,
							color: '#666',
						}),
						singleValue: (provided: CSSStyleDeclaration) => ({
							...provided,
							paddingRight: '20px',
							// paddingTop: '1rem',
							fontSize: '0.875rem',
						}),
						placeholder: (provided: CSSStyleDeclaration) => ({
							...provided,
							fontSize: '0.875rem',
							// paddingTop: '1rem',
							color: '#6D7786',
						}),
						input: (provided: CSSStyleDeclaration) => ({
							...provided,
							height: '1rem',
							display: 'flex',
							alignItems: 'center',
						}),
						multiValue: (provided: CSSStyleDeclaration) => ({
							...provided,
							alignItems: 'center',
							backgroundColor: white
								? '#fff'
								: 'rgba(253, 245, 247, var(--tw-bg-opacity))',
							fontSize: '0.875rem',
							marginLeft: 0,
							margin: '0px',
						}),
						menuList: (provided: CSSStyleDeclaration) => ({
							...provided,
							backgroundColor: white
								? '#fff'
								: 'rgba(253, 245, 247, var(--tw-bg-opacity))',
						}),
						menuNotice: (provided: CSSStyleDeclaration) => ({
							...provided,
							backgroundColor: white
								? '#fff'
								: 'rgba(253, 245, 247, var(--tw-bg-opacity))',
						}),
					}}
				/>
			</div>
			{error && <FormError error={error} />}
		</div>
	);
}
