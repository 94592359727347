import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classnames from 'classnames';
import { InputMode } from '../../../types';
import { FormLabel } from '../FormLabel/FormLabel';
import FormError from '../FormError/FormError';

export type InputProps = {
	onChange: (value: string) => void;
	label?: string;
	type: string;
	placeholder?: string | undefined;
	icon?: IconProp;
	error?: string;
	white?: boolean;
	extraClasses?: string;
	value: string;
	inputMode?: InputMode;
	required?: boolean;
	tooltip?: string;
	name: string;
	disabled?: boolean;
};

export function Input({
	label,
	onChange,
	type,
	placeholder,
	error,
	white = false,
	value,
	inputMode = 'text',
	required = false,
	tooltip,
	extraClasses = '',
	name,
	disabled = false,
}: InputProps): JSX.Element {
	return (
		<div className={classnames('flex flex-col w-full', extraClasses)}>
			<div
				className={classnames(
					'flex flex-col w-full border-0 rounded-lg relative',
					{ 'bg-white': white, 'bg-pink-900': !white },
				)}
			>
				<FormLabel
					name={name}
					label={label}
					tooltip={tooltip}
					required={required}
				/>
				<input
					className={classnames(
						'leading-5 w-full px-2 pb-2 border-0 text-base m-0 text-slate-900 rounded-lg focus:ring-lightBlue-500',
						{ 'bg-white': white, 'bg-pink-900': !white },
					)}
					onChange={event => onChange(event.target.value)}
					type={type}
					placeholder={placeholder}
					value={value ?? ''}
					aria-label={label}
					inputMode={inputMode}
					name={name}
					disabled={disabled}
				/>
			</div>
			{error && <FormError error={error} />}
		</div>
	);
}
