import React, { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
	children: ReactNode;
	icon: IconProp;
};

export default function GenericToast({ children, icon }: Props) {
	return (
		<div className='flex items-center text-white'>
			<FontAwesomeIcon icon={icon} className='mr-2' />
			<div> {children} </div>
		</div>
	);
}
