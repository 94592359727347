import React, { ReactNode } from 'react';
import classNames from 'classnames';
import GATracker from '../GATracker/GATracker';
import AlertsSignupModal from '../LandingPage/Modals/AlertsSignupModal';
import { useStore } from '../../store/store';

type Props = {
	children: ReactNode;
	bg?: string;
};

export default function Screen({ bg = 'bg-white', children }: Props) {
	const showAlertsSignupModal = useStore(state => state.showAlertsSignupModal);
	const setShowAlertsSignupModal = useStore(
		state => state.setShowAlertsSignupModal,
	);

	return (
		<div
			id='app'
			className={classNames(
				'absolute top-0 left-0 w-full min-h-screen overflow-x-hidden font-light',
				bg,
			)}
		>
			<AlertsSignupModal
				show={showAlertsSignupModal}
				onClose={() => {
					setShowAlertsSignupModal(false);
				}}
			/>
			<GATracker />
			<div className='w-screen min-h-screen flex flex-col'>{children}</div>
		</div>
	);
}
