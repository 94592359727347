import { QueryObserverResult, useQuery } from 'react-query';
import { GetKeywords } from '../requests/GetKeywords';

export default function useKeywords(): QueryObserverResult<string[]> {
	return useQuery(
		'keywords',
		async () => {
			const { data } = await GetKeywords();
			return data.map(({ value }) => value);
		},
		{
			refetchOnWindowFocus: false,
		},
	);
}
